import { graphql } from 'gatsby'
import { EditorProvider } from '@tiptap/react'
import { Header, Footer } from '@/components'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import { Box } from '@mui/material'
import React, { useMemo, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import {
  NzlContent,
  ItaContent,
  AusContent,
  enContent,
  plContent,
  EspContent,
} from '@/components/TermsOfUse/config'
import { UAEContent, UAEarContent } from '@/components/TermsOfUse/UAETermOfUse'
import { getUrlParam, transCountry } from '@/components/PrivacyPolicy/config'

const extensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Link.configure({
    HTMLAttributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  }),
]

const content = `
<h2 style="text-align:center">
  TERMS OF USE
</h2>
<p/>
<p>
  <strong>IMILE DELIVERY BRAZIL LTDA.</strong>, registered under CNPJ: 47.173.294/0001-17, with address at Alameda Rio Negro No. 585, <br/>
  Suite 51, 5th floor, Alphaville, Barueri/SP, ZIP Code: 06470-040, Brazil ("<strong>iMile</strong>").
</p>
<p>
  On this page, you will find the standard Terms of Use and Conditions applicable to the iMile website. Please note that there <br/>
  may be specific terms and conditions applicable to certain countries.
</p>
<h3>
  USE OF INTERACTIVE FEATURES
</h3>
<p>
  <strong>iMile</strong> provides interactive features, such as tracking, exclusively for specified purposes.
</p>
<h3>
  ACCURACY OF THE WEBSITE
</h3>
<p>
  This site may contain inaccuracies that will be corrected by <strong>iMile</strong> as identified. Information is updated regularly, but <br/>
  inaccuracies may occur between updates.
</p>
<h3>
  VIRUSES
</h3>
<p>
  <strong>iMile</strong> endeavors to mitigate viruses on its site, but cannot guarantee complete removal. Caution is advised when <br/>
  downloading information.
</p>
<h3>
  DISCLAIMER
</h3>
<p>
  Services, content, and information are provided "as is". <strong>iMile</strong> makes no warranties, express or implied, including warranties <br/>
  of fitness for a particular purpose.
</p>
<h3>
  LIMITATION OF LIABILITY
</h3>
<p>
  Under no circumstances will <strong>iMile</strong> be liable for indirect, consequential, or loss of profit damages related to the use of this <br/>
  site or its systems.
</p>
<h3>
  DISCLOSURE OF INFORMATION
</h3>
<p>
  Information provided to <strong>iMile</strong> is confidential and will not be disclosed except as necessary for the provision of services.
</p>
<p style="text-align:center"><strong>* * *</strong></p>
`

const TermsOfUse = (props) => {
  const [country, setCountry] = useState<string | undefined>('')
  const [lang, setLang] = useState<string | undefined>('')

  const BraContent = () => (
    <EditorProvider extensions={extensions} content={content} editable={false}></EditorProvider>
  )

  const showContent: any = useMemo(() => {
    let t = enContent
    if (country === 'Italy' && lang === 'it_IT') {
      t = ItaContent
    }
    if (country === 'Australia') {
      t = AusContent
    }
    if (country === 'Poland') {
      t = plContent
    }
    if (country === 'New Zealand') {
      t = NzlContent
    }
    if (country === 'Brazil') {
      t = BraContent
    }
    if (country === 'Spain') {
      t = EspContent
    }
    if (country === 'United Arab Emirates') {
      t = UAEarContent
    }
    if (country === 'United Arab Emirates' && lang === 'en_US') {
      t = UAEContent
    }
    return t
  }, [country, lang])

  useEffect(() => {
    const urlCountry =
      (getUrlParam('country') && transCountry[getUrlParam('country')]) || Cookies.get('COUNTRY')
    const urlLang = getUrlParam('language') || Cookies.get('EXPRESS_LANG')
    setCountry(urlCountry)
    setLang(urlLang)
  }, [])

  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box pt={15} display='flex' alignItems='center' justifyContent='center'>
        {showContent()}
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default TermsOfUse

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["TermsOfUse"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
