import { Box } from '@mui/material'

const sx = {
  width: ['100%', '800px'],
  pb: '50px',
  '.center': {
    textAlign: 'center',
  },
  '.bold': {
    fontWeight: 'bold',
  },
  '.dl': {
    py: '10px',
  },
  '.blue': {
    color: '#1890ff',
  },
  '.pl': {
    pl: '20px',
  },
}

export const UAEContent = () => {
  return (
    <Box sx={sx}>
      <h2 className='center bold'>Terms of Use for iMile UAE Website</h2>
      <div className='dl'>Last Updated: [14.11.2024]</div>
      <div>
        These Terms of Use (hereinafter as the “<b>Terms</b>” or “<b>Terms and Conditions</b>”)
        apply to you personally and when you use of the iMile United Arab Emirates (“<b>UAE</b>”)
        Website at <a href='https://www.imile.com/'>https://www.imile.com/</a> (hereinafter as the “
        <b>Website</b>”). This website is created and maintained by iMile, a company duly
        incorporated and established under the laws of the United Arab Emirates whose registered
        address is WH 01, Plot No 93-0, Eurostar Communications Building, Dubai Investment Park
        First, Po Box- 454158, Dubai, United Arab Emirates and whose registered number is 7393699
        (hereinafter as “<b>iMile</b>”).
      </div>
      <div className='dl'>
        By using the Website, you agree to these Terms and Conditions of use. Your use of the
        Website and the functionalities provided to you within the Website shall only be admissible
        subject to your prior acceptance of the Terms.{' '}
      </div>
      <div className='dl blue'>
        IF YOU DO NOT AGREE WITH ANY OF THE TERMS, PLEASE DO NOT USE THE WEBSITE.
      </div>
      <div className='dl bold'>1. Acceptance of Terms</div>
      <div className='dl'>
        By accessing or using this website (the "<b>Site</b>"), you agree to comply with and be
        bound by these Terms of Use (the "<b>Terms</b>") and our Privacy Policy. If you do not agree
        to these Terms, please do not use the Site.
      </div>
      <div className='dl bold'>2. Changes to Terms</div>
      <div className='dl'>
        iMile reserves the right to change, modify, add and remove portions of these Terms at any
        time, without prior written notice.
      </div>
      <div className='dl bold'>3. Software Content</div>
      <div className='dl'>
        iMile or its licensors own and retain all rights, including all applicable intellectual
        property rights, in all software on this Website, in both source and binary forms, sample
        code, APIs, SDKs, associated documentation, and other related materials (collectively, "
        <b>Software Content</b>").
      </div>
      <div className='dl'>
        Unless you have obtained a relevant license through other agreement entered into between you
        and iMile, nothing in these Terms grants any right or license to Software Content to you,
        and you shall not reverse engineer, decompile, disassemble, split, adapt, implant, or
        implement other derivative works on the Software Content.
      </div>
      <div className='dl bold'>4. Intellectual Property Rights</div>
      <div className='dl'>
        The content on the Site, including text, images, graphics, logos, and trademarks
        (collectively, the "<b>Content</b>"), is owned by iMile or its licensors and is protected by
        copyright, trademark, and other intellectual property laws.
      </div>
      <div className='dl'>
        You may not be permitted to use, copy, reproduce, distribute, or modify in any way the
        Content without our prior written consent, except as permitted by these Terms, or without
        obtaining explicit prior written permission from iMile or such licensors as applicable.
      </div>
      <div className='dl bold'>5. Privacy and Data Collection</div>
      <div className='dl'>
        iMile will only use your personal data in accordance with iMile's{' '}
        <a href='https://www.imile.com/privacy-policy/'>Privacy Policy</a>. Please refer to our
        Privacy Policy for more information.
      </div>
      <div className='dl'>
        Your use of the Site is also governed by our{' '}
        <a href='https://www.imile.com/privacy-policy/'>Privacy Policy</a>, which explains how we
        collect, use, and protect your personal data. By using the Site, you consent to the data
        practices described in our Privacy Policy.
      </div>
      <div className='dl bold'>6. Copyright</div>
      <div className='dl'>
        All contents on this Website are copyrighted by iMile or applicable licensors unless
        otherwise stated. Without prior written consent of iMile or other related parties, any
        content on the Website shall not be reproduced, modified, promoted, distributed,
        photocopied, played, disassembled, reverse engineered, decompiled, linked or transmitted
        with hyperlinks, loaded into other servers in "mirroring method", stored in information
        retrieval system, or otherwise used for any other purpose by any person by any means.
      </div>
      <div className='dl bold'>7. Website Content</div>
      <div className='dl'>
        The Website Contents include but are not limited to text, images, data, web pages, and links
        offered on or through the Website. This Website and its contents are provided for your
        convenience only. Although iMile has attempted to provide accurate information on this
        Website, it assumes no obligation or responsibility whatsoever regarding the accuracy and
        completeness of the information. The Website Contents may contain inadvertent inaccuracies
        or typographical errors. These will be corrected at iMile's discretion, as they are found.
      </div>
      <div className='dl bold'>8. Links to Third Parties</div>
      <div className='dl'>
        The Site may contain links to third-party websites. These links are provided for convenience
        and do not imply endorsement of the linked websites. We are not responsible for the content
        or practices of third-party websites.
      </div>
      <div className='dl'>
        iMile shall not be responsible for any content of any such websites. You might need to
        review and agree to applicable rules of use when using such websites. In addition, a link to
        third-party website does not imply that iMile endorses the site or the products or services
        referenced therein.
      </div>
      <div className='dl bold'>9. Viruses</div>
      <div className='dl'>
        iMile makes all reasonable duty of care and attempts to exclude viruses from these web
        pages, but it cannot ensure this exclusion, and no liability shall be accepted for viruses.
        Please take all appropriate safeguards before downloading information from these web pages,
        as the onus and responsibility is placed on you as a user of the website.
      </div>
      <div className='dl bold'>10. Disclaimer of Warranties</div>
      <div className='dl'>
        All information provided on this website is provided on an "as is" basis without warranties,
        guarantees or representations of any kind. iMile hereby expressly disclaims, to the fullest
        extent permitted by law, all express, implied, statutory or other warranties, guarantees or
        representations, including but not limited to, the implied warranties of merchantability,
        fitness for a particular purpose, or non-infringement of third-party rights.
      </div>
      <div className='dl'>
        <b>Site Availability:</b> We do not guarantee that the Site will always be available or free
        of errors, interruptions, or viruses.
      </div>
      <div className='dl bold'>11. Limitation of Liability</div>
      <div className='dl'>
        • To the fullest extent permitted by law, neither iMile nor any of its affiliates,
        subsidiaries, directors, agents, employees or other representatives shall be liable for any
        direct, indirect, special, incidental, consequential, punitive, and/or exemplary damages
        including without limitation, loss of profits or revenues, loss of data, and/or loss of
        business, in connection with this Website or the use of or inability to use this Website or
        reliance on the contents contained herein, even if iMile is advised of the possibility of
        such damages or whether it is associated with the possibility of such damages arising out of
        the use or inability to use the Site or its Content. Unless otherwise agreed in writing by
        iMile in any applicable contract, to the extent permitted by the applicable law, iMile shall
        not be liable for the materials of this Website, regardless of the reason for the liability
        or the tort theory it is based on.
      </div>
      <div className='dl bold'>12. User Responsibilities</div>
      <div className='dl'>
        You agree to use this Site for lawful purposes only and in accordance with these Terms. You
        may not:
      </div>
      <div className='pl'>
        {[
          '•	Violate any local, state, or international law.',
          '•	Use the Site to distribute malware or harmful code.',
          '•	Engage in any activity that could disrupt or damage the functionality of the Site.',
          '•	Upload or share content that is offensive, defamatory, or infringes on intellectual property rights.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl'>
        You agree that iMile may, at its sole discretion and without prior notice to you, terminate
        your access to the Website and/or block your future access to the Website, if iMile
        determines that you have violated these Terms, you agree to comply with the usage of this
        website or any other agreements or guidelines which may be associated with your use of the
        Website.
      </div>
      <div className='dl bold'>13. Account Creation and Security</div>
      <div className='dl'>
        To access certain features or services, you may be required to create an account. You agree
        to:
      </div>
      <div className='pl'>
        {[
          '•	Provide accurate, current, and complete information during the registration process.',
          '•	Maintain the confidentiality of your account login details, including your password.',
          '•	Notify us immediately if you become aware of any unauthorized use of your account.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl'>
        You are solely responsible for any activity that occurs under your account.
      </div>
      <div className='dl bold'>14. Disclosure of Information</div>
      <div className='dl'>
        All information provided to iMile by visitors to these web pages is considered to be
        confidential information and shall not be disclosed by iMile to any third party except as
        may be required for the provision of the services or required by law or any governmental
        authority.
      </div>
      <div className='dl bold'>15. Indemnification</div>
      <div className='dl'>
        You agree to indemnify and hold harmless iMile, its affiliates, officers, employees, and
        agents from any claims, losses, liabilities, damages, or expenses (including reasonable
        attorneys' fees) arising out of your use of the Site, violation of these Terms, or
        infringement of any third-party rights.
      </div>
      <div className='dl bold'>16. Force Majeure</div>
      <div className='dl'>
        We will not be held liable for any delay or failure to perform under these Terms due to
        circumstances beyond our reasonable control, including but not limited to natural disasters,
        acts of war, pandemics, or technical failures.
      </div>
      <div className='dl bold'>17. Severability</div>
      <div className='dl'>
        If any provision of these Terms is found to be invalid or unenforceable, the remaining
        provisions will remain in full force and effect.
      </div>
      <div className='dl bold'>18. Contact Us</div>
      <div className='dl'>
        If you have any questions about these Terms of Use, please{' '}
        <span className='blue'>Contact us</span>.
      </div>
      <div className='dl bold'>19. Termination</div>
      <div className='dl'>
        We reserve the right to suspend or terminate your access to the Site at our sole discretion,
        without notice, for any reason, including if you violate these Terms.
      </div>
      <div className='dl bold'>20. Governing Law</div>
      <div className='dl'>
        These Terms shall be governed by and construed in accordance with the laws of the United
        Arab Emirates. Any legal action arising out of or in connection with these Terms shall be
        brought in the courts located in Dubai, and you consent to the exclusive jurisdiction of
        such courts.
      </div>
    </Box>
  )
}

export const UAEarContent = () => {
  return (
    <Box
      sx={{
        ...sx,
        direction: 'rtl',
        textAlign: 'left',
      }}
    >
      <h2 className='center bold'>شروط استخدام موقع شركة ايمايل في الإمارات العربية المتحدة</h2>
      <div className='dl'>آخر تحديث: [14/11/2024]</div>
      <div className='dl'>
        تنطبق شروط الاستخدام هذه (يُشار إليها فيما بعد بـ "الشروط" أو "الأحكام والشروط") عليك شخصياً
        عند استخدامك لموقع iMile في دولة الإمارات العربية المتحدة ("الإمارات") على الرابط
        <a href='https://www.imile.com'>https://www.imile.com</a> / (يُشار إليه فيما بعد بـ
        "الموقع"). تم إنشاء هذا الموقع وصيانته بواسطة شركة ايمايل، وهي شركة مسجلة ومؤسسة وفقاً
        لقوانين دولة الإمارات العربية المتحدة، وعنوانها المسجل هو مستودع 01، قطعة رقم 93-0، مبنى
        يوروستار للاتصالات، مجمع دبي للاستثمار الأول، صندوق بريد 454158، دبي، الإمارات العربية
        المتحدة، ورقمها المسجل هو 7393699 (يُشار إليها فيما بعد بـ " ايمايل").
      </div>
      <div className='dl'>
        باستخدامك للموقع، فإنك توافق على هذه الشروط والأحكام الخاصة بالاستخدام. ويكون استخدامك
        للموقع والوظائف المقدمة لك فيه مقبولاً فقط بعد موافقتك المسبقة على الشروط.
      </div>
      <div className='dl bold'>إذا كنت لا توافق على أي من هذه الشروط، يرجى عدم استخدام الموقع.</div>
      <div className='dl bold'>1. قبول الشروط</div>
      <div className='dl'>
        بمجرد الوصول إلى هذا الموقع أو استخدامه (المشار إليه بـ "الموقع")، فإنك توافق على الامتثال
        لهذه الشروط (المشار إليها بـ "الشروط") وسياسة الخصوصية الخاصة بنا والالتزام بها. إذا كنت لا
        توافق على هذه الشروط، يرجى عدم استخدام الموقع.
      </div>
      <div className='dl bold'>2. التعديلات على الشروط</div>
      <div className='dl'>
        تحتفظ شركة ايمايل بحقها في تغيير أو تعديل أو إضافة أو إزالة أجزاء من هذه الشروط في أي وقت
        دون إشعار كتابي مسبق.
      </div>
      <div className='dl bold'>3. محتوى البرمجيات</div>
      <div className='dl'>
        تملك شركة ايمايل أو الجهات المرخصة لها جميع الحقوق، بما في ذلك جميع حقوق الملكية الفكرية
        المعمول بها، في جميع البرمجيات الموجودة على هذا الموقع، سواء في شكلها البرمجي أو الثنائي،
        بما في ذلك عينات الشيفرة، واجهات برمجة التطبيقات، أدوات تطوير البرمجيات، الوثائق المرتبطة،
        وغيرها من المواد ذات الصلة (المشار إليها مجتمعة بـ "محتوى البرمجيات").{' '}
      </div>
      <div className='dl'>
        ما لم تكن قد حصلت على ترخيص ذي صلة من خلال اتفاقية أخرى مبرمة بينك وبين شركة ايمايل، لا
        تمنحك هذه الشروط أي حق أو ترخيص لاستخدام محتوى البرمجيات، ولا يجوز لك إجراء الهندسة العكسية،
        أو فك الشيفرة، أو تفكيك، أو تقسيم، أو تعديل، أو زرع، أو تنفيذ أعمال مشتقة أخرى على محتوى
        البرمجيات.
      </div>
      <div className='dl bold'>4. حقوق الملكية الفكرية</div>
      <div className='dl'>
        المحتوى على الموقع، بما في ذلك النصوص، الصور، الرسوم البيانية، الشعارات، والعلامات التجارية
        (المشار إليها مجتمعة بـ "المحتوى")، مملوك لـشركة ايمايل أو مرخصيها ومحمياً بموجب حقوق الطبع
        والنشر، والعلامات التجارية، والقوانين الأخرى المتعلقة بالملكية الفكرية.
      </div>
      <div className='dl'>
        لا يُسمح لك باستخدام أو نسخ أو إعادة إنتاج أو توزيع أو تعديل المحتوى بأي طريقة بدون موافقة
        خطية مسبقة من شركة ايمايل، إلا كما هو مسموح به بموجب هذه الشروط أو بدون الحصول على إذن خطي
        مسبق من شركة ايمايل أو مرخصيها المعنيين.
      </div>
      <div className='dl bold'>5. الخصوصية وجمع البيانات</div>
      <div className='dl'>
        ستستخدم شركة ايمايل بياناتك الشخصية فقط وفقاً لسياسة الخصوصية الخاصة بـشركة ايمايل. يرجى
        الرجوع إلى سياسة الخصوصية الخاصة بنا لمزيد من المعلومات.
      </div>
      <div className='dl'>
        يخضع استخدامك للموقع أيضاً لسياسة الخصوصية الخاصة بنا، التي تشرح كيفية جمعنا واستخدامنا
        وحمايتنا لبياناتك الشخصية. باستخدامك للموقع، فإنك توافق على الممارسات المتعلقة بالبيانات
        الموضحة في سياسة الخصوصية الخاصة بنا.
      </div>
      <div className='dl bold'>6. حقوق الطبع والنشر</div>
      <div className='dl'>
        جميع المحتويات على هذا الموقع محمية بموجب حقوق الطبع والنشر الخاصة بـشركة ايمايل أو المرخصين
        المعنيين ما لم يُذكر خلاف ذلك.
      </div>
      <div className='dl'>
        دون الحصول على موافقة خطية مسبقة من شركة ايمايل أو الأطراف ذات الصلة الأخرى، لا يجوز إعادة
        إنتاج أو تعديل أو ترويج أو توزيع أو نسخ أو تشغيل أو تفكيك أو إجراء هندسة عكسية أو فك شيفرة
        أو ربط المحتوى أو نقله باستخدام الروابط التشعبية، أو تحميله على خوادم أخرى بطريقة "المرايا"،
        أو تخزينه في أنظمة استرجاع المعلومات، أو استخدامه لأي غرض آخر بواسطة أي شخص بأي وسيلة.
      </div>
      <div className='dl bold'>7. محتوى الموقع</div>
      <div className='dl'>
        يتضمن محتوى الموقع، ولكن لا يقتصر على، النصوص، الصور، البيانات، صفحات الويب، والروابط
        المعروضة على الموقع أو من خلاله. يتم توفير هذا الموقع ومحتوياته لراحتك فقط. على الرغم من أن
        شركة ايمايل قد حاولت توفير معلومات دقيقة على هذا الموقع، إلا أنها لا تتحمل أي التزام أو
        مسؤولية فيما يتعلق بدقة أو اكتمال المعلومات. قد يحتوي محتوى الموقع على أخطاء غير مقصودة أو
        أخطاء طباعية. سيتم تصحيح هذه الأخطاء حسب تقدير شركة ايمايل عند اكتشافها.
      </div>
      <div className='dl bold'>8. الروابط إلى أطراف ثالثة</div>
      <div className='dl'>
        قد يحتوي الموقع على روابط إلى مواقع إلكترونية تابعة لأطراف ثالثة. تم توفير هذه الروابط
        للراحة فقط ولا تعني تأييد المواقع المرتبطة. نحن غير مسؤولين عن محتوى أو ممارسات المواقع
        الإلكترونية التابعة لأطراف ثالثة.
      </div>
      <div className='dl'>
        لن تكون شركة ايمايل مسؤولة عن أي محتوى من هذه المواقع. قد تحتاج إلى مراجعة والموافقة على
        القواعد المعمول بها عند استخدام هذه المواقع. بالإضافة إلى ذلك، فإن الرابط إلى موقع ويب تابع
        لطرف ثالث لا يعني أن شركة ايمايل تؤيد الموقع أو المنتجات أو الخدمات المشار إليها فيه.
      </div>
      <div className='dl bold'>9. الفيروسات</div>
      <div className='dl'>
        تتخذ شركة ايمايل جميع الاحتياطات المعقولة وتبذل قصارى جهدها لاستبعاد الفيروسات من هذه
        الصفحات الإلكترونية، ولكن لا يمكنها ضمان هذا الاستبعاد، ولن تتحمل أي مسؤولية عن الفيروسات.
        يرجى اتخاذ جميع الاحتياطات المناسبة قبل تنزيل المعلومات من هذه الصفحات، حيث يقع على عاتقك
        كمستخدم للموقع مسؤولية اتخاذ هذه الاحتياطات.
      </div>
      <div className='dl bold'>10. إخلاء المسؤولية عن الضمانات</div>
      <div className='dl'>
        جميع المعلومات المقدمة على هذا الموقع مقدمة على أساس "كما هي" بدون ضمانات أو كفالات أو
        تصريحات من أي نوع. وبموجب ذلك، تتنصل شركة ايمايل صراحة، إلى أقصى حد يسمح به القانون، من جميع
        الضمانات والتعهدات الصريحة أو الضمنية أو القانونية أو غيرها، بما في ذلك على سبيل المثال لا
        الحصر، الضمانات الضمنية الخاصة بالقابلية للتسويق، الملاءمة لغرض معين، أو عدم انتهاك حقوق
        الأطراف الثالثة.
      </div>
      <div className='dl'>
        توفر الموقع: لا نضمن أن الموقع سيكون متاحًا دائمًا أو خاليًا من الأخطاء أو الانقطاعات أو
        الفيروسات.
      </div>
      <div className='dl bold'>11. تحديد المسؤولية</div>
      <div className='dl'>
        - إلى أقصى حد يسمح به القانون، لا تكون شركة ايمايل ولا أي من شركاتها التابعة أو الفرعية أو
        أعضائها في مجلس الإدارة أو وكلائها أو موظفيها أو ممثليها مسؤولين عن أي أضرار مباشرة أو غير
        مباشرة أو خاصة أو عرضية أو تبعية أو عقابية و/أو نموذجية، بما في ذلك على سبيل المثال لا
        الحصر، خسارة الأرباح أو الإيرادات، خسارة البيانات، و/أو خسارة الأعمال، فيما يتعلق بهذا
        الموقع أو استخدامه أو عدم القدرة على استخدامه أو الاعتماد على المحتويات الموجودة فيه، حتى
        وإن تم إبلاغ شركة ايمايل بإمكانية حدوث هذه الأضرار أو إذا كان ذلك مرتبطًا بإمكانية حدوث هذه
        الأضرار الناتجة عن استخدام الموقع أو عدم القدرة على استخدامه أو محتوياته. ما لم يتم الاتفاق
        على خلاف ذلك كتابةً من قبل شركة ايمايل في أي عقد ساري، إلى الحد الذي يسمح به القانون المعمول
        به، لا تتحمل شركة ايمايل أي مسؤولية عن مواد هذا الموقع، بغض النظر عن السبب في المسؤولية أو
        النظرية التي استندت إليها.
      </div>
      <div className='dl bold'>12.مسؤوليات المستخدم</div>
      <div className='dl'>
        توافق على استخدام هذا الموقع للأغراض القانونية فقط ووفقاً لهذه الشروط. لا يجوز لك أن:
      </div>
      <div>
        {[
          '- تنتهك أي قانون محلي أو حكومي أو دولي.',
          '- تستخدم الموقع لتوزيع البرمجيات الضارة أو الأكواد المدمرة.',
          '- تشارك في أي نشاط قد يعطل أو يتسبب في تلف وظيفة الموقع.',
          '- تقوم بتحميل أو مشاركة محتوى مسيء أو تشهيري أو ينتهك حقوق الملكية الفكرية.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl'>
        توافق على أن شركة ايمايل قد تقوم، وفقاً لتقديرها الخاص ودون إشعار مسبق لك، بإنهاء وصولك إلى
        الموقع و/أو حظر وصولك المستقبلي إلى الموقع، إذا قررت شركة ايمايل أنك قد انتهكت هذه الشروط.
        توافق على الالتزام باستخدام هذا الموقع أو أي اتفاقيات أو إرشادات أخرى قد تكون مرتبطة
        باستخدامك للموقع.
      </div>
      <div className='dl bold'>13. إنشاء الحساب والأمن</div>
      <div className='dl'>للوصول إلى بعض الميزات أو الخدمات، قد يُطلب منك إنشاء حساب.</div>
      <div className='dl'>توافق على:</div>
      <div>
        {[
          '- تقديم معلومات دقيقة وحديثة وكاملة خلال عملية التسجيل.',
          '- الحفاظ على سرية تفاصيل تسجيل الدخول لحسابك، بما في ذلك كلمة المرور.',
          '- إبلاغنا فورًا إذا كنت على علم بأي استخدام غير مصرح به لحسابك.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl'>أنت المسؤول الوحيد عن أي نشاط يحدث تحت حسابك.</div>
      <div className='dl bold'>14. كشف المعلومات</div>
      <div className='dl'>
        جميع المعلومات المقدمة لـشركة ايمايل من قبل زوار هذه الصفحات الإلكترونية تعتبر معلومات سرية
        ولن تقوم شركة ايمايل بالكشف عنها لأي طرف ثالث إلا إذا كان ذلك مطلوبًا لتقديم الخدمات أو
        بموجب القانون أو من قبل أي سلطة حكومية.
      </div>
      <div className='dl bold'>15. التعويض</div>
      <div className='dl'>
        توافق على تعويض شركة ايمايل وشركاتها التابعة، ومسؤوليها، وموظفيها، ووكلائها وحمايتها من أي
        مطالبات أو خسائر أو مسؤوليات أو أضرار أو نفقات (بما في ذلك أتعاب المحاماة المعقولة) التي
        تنشأ عن استخدامك للموقع، أو انتهاكك لهذه الشروط، أو انتهاك حقوق أي طرف ثالث.
      </div>
      <div className='dl bold'>16. القوة القاهرة</div>
      <div className='dl'>
        لن نكون مسؤولين عن أي تأخير أو فشل في الأداء بموجب هذه الشروط بسبب ظروف خارجة عن سيطرتنا
        المعقولة، بما في ذلك على سبيل المثال لا الحصر الكوارث الطبيعية، وأعمال الحرب، والأوبئة، أو
        الفشل الفني.
      </div>
      <div className='dl bold'>17. قابلية الفصل</div>
      <div className='dl'>
        إذا تم العثور على أي حكم من هذه الشروط غير صالح أو غير قابل للتنفيذ، ستظل الأحكام المتبقية
        سارية المفعول وفعالة بالكامل.
      </div>
      <div className='dl bold'>18. اتصل بنا </div>
      <div className='dl'>إذا كانت لديك أي أسئلة حول هذه الشروط، يرجى الاتصال بنا.</div>
      <div className='dl bold'>19. الإنهاء</div>
      <div className='dl'>
        نحتفظ بالحق في تعليق أو إنهاء وصولك إلى الموقع وفقًا لتقديرنا الخاص، دون إشعار، لأي سبب كان،
        بما في ذلك إذا قمت بانتهاك هذه الشروط.
      </div>
      <div className='dl bold'>20. القانون الحاكم</div>
      <div className='dl'>
        تخضع هذه الشروط وتُفسر وفقًا لقوانين دولة الإمارات العربية المتحدة. وأي إجراء قانوني ينشأ عن
        أو يتعلق بهذه الشروط يجب أن يُرفع في المحاكم الواقعة في دبي، وتوافق على الاختصاص القضائي
        الحصري لتلك المحاكم.
      </div>
    </Box>
  )
}
